/**
 * Tribuu API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Reference { 
    uid: string;
    fullname: string;
    companyName: string | null;
    phone: string;
    isProfessional: boolean;
    contact: Reference.ContactEnum;
    verificationId: string;
    createdAt: Date;
    updatedAt: Date;
}
export namespace Reference {
    export type ContactEnum = 'RRHH' | 'CHIEF' | 'FAMILIAR' | 'FRIEND' | 'KNOWN';
    export const ContactEnum = {
        RRHH: 'RRHH' as ContactEnum,
        CHIEF: 'CHIEF' as ContactEnum,
        FAMILIAR: 'FAMILIAR' as ContactEnum,
        FRIEND: 'FRIEND' as ContactEnum,
        KNOWN: 'KNOWN' as ContactEnum
    };
}


