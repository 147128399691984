/**
 * Tribuu API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Triber { 
    uid: string;
    folio: string | null;
    about: string;
    isEmployed: boolean | null;
    code: string | null;
    name: string;
    lastname: string;
    phone: string | null;
    imageUrl: string;
    country: Triber.CountryEnum;
    birthdate: Date | null;
    postalCode: string;
    createdAt: Date;
    updatedAt: Date;
    verifiedPhone: Date | null;
    status: Triber.StatusEnum;
    resumeId: string | null;
    userId: string;
    errorsPending: object | null;
    publicationDraft: object | null;
    resumeDraft: object | null;
    referredCodeId: string | null;
    defaultScheduleId: string | null;
    enabled: boolean | null;
    /**
     * Nombre completo del triber
     */
    fullName: string;
}
export namespace Triber {
    export type CountryEnum = 'AF' | 'AX' | 'AL' | 'DZ' | 'AS' | 'AD' | 'AO' | 'AI' | 'AQ' | 'AG' | 'AR' | 'AM' | 'AW' | 'AU' | 'AT' | 'AZ' | 'BS' | 'BH' | 'BD' | 'BB' | 'BY' | 'BE' | 'BZ' | 'BJ' | 'BM' | 'BT' | 'BO' | 'BA' | 'BW' | 'BV' | 'BR' | 'IO' | 'BN' | 'BG' | 'BF' | 'BI' | 'KH' | 'CM' | 'CA' | 'CV' | 'KY' | 'CF' | 'TD' | 'CL' | 'CN' | 'CX' | 'CC' | 'CO' | 'KM' | 'CG' | 'CD' | 'CK' | 'CR' | 'CI' | 'HR' | 'CU' | 'CY' | 'CZ' | 'DK' | 'DJ' | 'DM' | 'DO' | 'EC' | 'EG' | 'SV' | 'GQ' | 'ER' | 'EE' | 'ET' | 'FK' | 'FO' | 'FJ' | 'FI' | 'FR' | 'GF' | 'PF' | 'TF' | 'GA' | 'GM' | 'GE' | 'DE' | 'GH' | 'GI' | 'GR' | 'GL' | 'GD' | 'GP' | 'GU' | 'GT' | 'GG' | 'GN' | 'GW' | 'GY' | 'HT' | 'HM' | 'VA' | 'HN' | 'HK' | 'HU' | 'IS' | 'IN' | 'ID' | 'IR' | 'IQ' | 'IE' | 'IM' | 'IL' | 'IT' | 'JM' | 'JP' | 'JE' | 'JO' | 'KZ' | 'KE' | 'KI' | 'KP' | 'KR' | 'KW' | 'KG' | 'LA' | 'LV' | 'LB' | 'LS' | 'LR' | 'LY' | 'LI' | 'LT' | 'LU' | 'MO' | 'MK' | 'MG' | 'MW' | 'MY' | 'MV' | 'ML' | 'MT' | 'MH' | 'MQ' | 'MR' | 'MU' | 'YT' | 'MX' | 'FM' | 'MD' | 'MC' | 'MN' | 'MS' | 'MA' | 'MZ' | 'MM' | 'NA' | 'NR' | 'NP' | 'NL' | 'AN' | 'NC' | 'NZ' | 'NI' | 'NE' | 'NG' | 'NU' | 'NF' | 'MP' | 'NO' | 'OM' | 'PK' | 'PW' | 'PS' | 'PA' | 'PG' | 'PY' | 'PE' | 'PH' | 'PN' | 'PL' | 'PT' | 'PR' | 'QA' | 'RE' | 'RO' | 'RU' | 'RW' | 'SH' | 'KN' | 'LC' | 'PM' | 'VC' | 'WS' | 'SM' | 'ST' | 'SA' | 'SN' | 'CS' | 'SC' | 'SL' | 'SG' | 'SK' | 'SI' | 'SB' | 'SO' | 'ZA' | 'GS' | 'ES' | 'LK' | 'SD' | 'SR' | 'SJ' | 'SZ' | 'SE' | 'CH' | 'SY' | 'TW' | 'TJ' | 'TZ' | 'TH' | 'TL' | 'TG' | 'TK' | 'TO' | 'TT' | 'TN' | 'TR' | 'TM' | 'TC' | 'TV' | 'UG' | 'UA' | 'AE' | 'GB' | 'US' | 'UM' | 'UY' | 'UZ' | 'VU' | 'VE' | 'VN' | 'VG' | 'VI' | 'WF' | 'EH' | 'YE' | 'ZM' | 'ZW';
    export const CountryEnum = {
        AF: 'AF' as CountryEnum,
        AX: 'AX' as CountryEnum,
        AL: 'AL' as CountryEnum,
        DZ: 'DZ' as CountryEnum,
        AS: 'AS' as CountryEnum,
        AD: 'AD' as CountryEnum,
        AO: 'AO' as CountryEnum,
        AI: 'AI' as CountryEnum,
        AQ: 'AQ' as CountryEnum,
        AG: 'AG' as CountryEnum,
        AR: 'AR' as CountryEnum,
        AM: 'AM' as CountryEnum,
        AW: 'AW' as CountryEnum,
        AU: 'AU' as CountryEnum,
        AT: 'AT' as CountryEnum,
        AZ: 'AZ' as CountryEnum,
        BS: 'BS' as CountryEnum,
        BH: 'BH' as CountryEnum,
        BD: 'BD' as CountryEnum,
        BB: 'BB' as CountryEnum,
        BY: 'BY' as CountryEnum,
        BE: 'BE' as CountryEnum,
        BZ: 'BZ' as CountryEnum,
        BJ: 'BJ' as CountryEnum,
        BM: 'BM' as CountryEnum,
        BT: 'BT' as CountryEnum,
        BO: 'BO' as CountryEnum,
        BA: 'BA' as CountryEnum,
        BW: 'BW' as CountryEnum,
        BV: 'BV' as CountryEnum,
        BR: 'BR' as CountryEnum,
        IO: 'IO' as CountryEnum,
        BN: 'BN' as CountryEnum,
        BG: 'BG' as CountryEnum,
        BF: 'BF' as CountryEnum,
        BI: 'BI' as CountryEnum,
        KH: 'KH' as CountryEnum,
        CM: 'CM' as CountryEnum,
        CA: 'CA' as CountryEnum,
        CV: 'CV' as CountryEnum,
        KY: 'KY' as CountryEnum,
        CF: 'CF' as CountryEnum,
        TD: 'TD' as CountryEnum,
        CL: 'CL' as CountryEnum,
        CN: 'CN' as CountryEnum,
        CX: 'CX' as CountryEnum,
        CC: 'CC' as CountryEnum,
        CO: 'CO' as CountryEnum,
        KM: 'KM' as CountryEnum,
        CG: 'CG' as CountryEnum,
        CD: 'CD' as CountryEnum,
        CK: 'CK' as CountryEnum,
        CR: 'CR' as CountryEnum,
        CI: 'CI' as CountryEnum,
        HR: 'HR' as CountryEnum,
        CU: 'CU' as CountryEnum,
        CY: 'CY' as CountryEnum,
        CZ: 'CZ' as CountryEnum,
        DK: 'DK' as CountryEnum,
        DJ: 'DJ' as CountryEnum,
        DM: 'DM' as CountryEnum,
        DO: 'DO' as CountryEnum,
        EC: 'EC' as CountryEnum,
        EG: 'EG' as CountryEnum,
        SV: 'SV' as CountryEnum,
        GQ: 'GQ' as CountryEnum,
        ER: 'ER' as CountryEnum,
        EE: 'EE' as CountryEnum,
        ET: 'ET' as CountryEnum,
        FK: 'FK' as CountryEnum,
        FO: 'FO' as CountryEnum,
        FJ: 'FJ' as CountryEnum,
        FI: 'FI' as CountryEnum,
        FR: 'FR' as CountryEnum,
        GF: 'GF' as CountryEnum,
        PF: 'PF' as CountryEnum,
        TF: 'TF' as CountryEnum,
        GA: 'GA' as CountryEnum,
        GM: 'GM' as CountryEnum,
        GE: 'GE' as CountryEnum,
        DE: 'DE' as CountryEnum,
        GH: 'GH' as CountryEnum,
        GI: 'GI' as CountryEnum,
        GR: 'GR' as CountryEnum,
        GL: 'GL' as CountryEnum,
        GD: 'GD' as CountryEnum,
        GP: 'GP' as CountryEnum,
        GU: 'GU' as CountryEnum,
        GT: 'GT' as CountryEnum,
        GG: 'GG' as CountryEnum,
        GN: 'GN' as CountryEnum,
        GW: 'GW' as CountryEnum,
        GY: 'GY' as CountryEnum,
        HT: 'HT' as CountryEnum,
        HM: 'HM' as CountryEnum,
        VA: 'VA' as CountryEnum,
        HN: 'HN' as CountryEnum,
        HK: 'HK' as CountryEnum,
        HU: 'HU' as CountryEnum,
        IS: 'IS' as CountryEnum,
        IN: 'IN' as CountryEnum,
        ID: 'ID' as CountryEnum,
        IR: 'IR' as CountryEnum,
        IQ: 'IQ' as CountryEnum,
        IE: 'IE' as CountryEnum,
        IM: 'IM' as CountryEnum,
        IL: 'IL' as CountryEnum,
        IT: 'IT' as CountryEnum,
        JM: 'JM' as CountryEnum,
        JP: 'JP' as CountryEnum,
        JE: 'JE' as CountryEnum,
        JO: 'JO' as CountryEnum,
        KZ: 'KZ' as CountryEnum,
        KE: 'KE' as CountryEnum,
        KI: 'KI' as CountryEnum,
        KP: 'KP' as CountryEnum,
        KR: 'KR' as CountryEnum,
        KW: 'KW' as CountryEnum,
        KG: 'KG' as CountryEnum,
        LA: 'LA' as CountryEnum,
        LV: 'LV' as CountryEnum,
        LB: 'LB' as CountryEnum,
        LS: 'LS' as CountryEnum,
        LR: 'LR' as CountryEnum,
        LY: 'LY' as CountryEnum,
        LI: 'LI' as CountryEnum,
        LT: 'LT' as CountryEnum,
        LU: 'LU' as CountryEnum,
        MO: 'MO' as CountryEnum,
        MK: 'MK' as CountryEnum,
        MG: 'MG' as CountryEnum,
        MW: 'MW' as CountryEnum,
        MY: 'MY' as CountryEnum,
        MV: 'MV' as CountryEnum,
        ML: 'ML' as CountryEnum,
        MT: 'MT' as CountryEnum,
        MH: 'MH' as CountryEnum,
        MQ: 'MQ' as CountryEnum,
        MR: 'MR' as CountryEnum,
        MU: 'MU' as CountryEnum,
        YT: 'YT' as CountryEnum,
        MX: 'MX' as CountryEnum,
        FM: 'FM' as CountryEnum,
        MD: 'MD' as CountryEnum,
        MC: 'MC' as CountryEnum,
        MN: 'MN' as CountryEnum,
        MS: 'MS' as CountryEnum,
        MA: 'MA' as CountryEnum,
        MZ: 'MZ' as CountryEnum,
        MM: 'MM' as CountryEnum,
        NA: 'NA' as CountryEnum,
        NR: 'NR' as CountryEnum,
        NP: 'NP' as CountryEnum,
        NL: 'NL' as CountryEnum,
        AN: 'AN' as CountryEnum,
        NC: 'NC' as CountryEnum,
        NZ: 'NZ' as CountryEnum,
        NI: 'NI' as CountryEnum,
        NE: 'NE' as CountryEnum,
        NG: 'NG' as CountryEnum,
        NU: 'NU' as CountryEnum,
        NF: 'NF' as CountryEnum,
        MP: 'MP' as CountryEnum,
        NO: 'NO' as CountryEnum,
        OM: 'OM' as CountryEnum,
        PK: 'PK' as CountryEnum,
        PW: 'PW' as CountryEnum,
        PS: 'PS' as CountryEnum,
        PA: 'PA' as CountryEnum,
        PG: 'PG' as CountryEnum,
        PY: 'PY' as CountryEnum,
        PE: 'PE' as CountryEnum,
        PH: 'PH' as CountryEnum,
        PN: 'PN' as CountryEnum,
        PL: 'PL' as CountryEnum,
        PT: 'PT' as CountryEnum,
        PR: 'PR' as CountryEnum,
        QA: 'QA' as CountryEnum,
        RE: 'RE' as CountryEnum,
        RO: 'RO' as CountryEnum,
        RU: 'RU' as CountryEnum,
        RW: 'RW' as CountryEnum,
        SH: 'SH' as CountryEnum,
        KN: 'KN' as CountryEnum,
        LC: 'LC' as CountryEnum,
        PM: 'PM' as CountryEnum,
        VC: 'VC' as CountryEnum,
        WS: 'WS' as CountryEnum,
        SM: 'SM' as CountryEnum,
        ST: 'ST' as CountryEnum,
        SA: 'SA' as CountryEnum,
        SN: 'SN' as CountryEnum,
        CS: 'CS' as CountryEnum,
        SC: 'SC' as CountryEnum,
        SL: 'SL' as CountryEnum,
        SG: 'SG' as CountryEnum,
        SK: 'SK' as CountryEnum,
        SI: 'SI' as CountryEnum,
        SB: 'SB' as CountryEnum,
        SO: 'SO' as CountryEnum,
        ZA: 'ZA' as CountryEnum,
        GS: 'GS' as CountryEnum,
        ES: 'ES' as CountryEnum,
        LK: 'LK' as CountryEnum,
        SD: 'SD' as CountryEnum,
        SR: 'SR' as CountryEnum,
        SJ: 'SJ' as CountryEnum,
        SZ: 'SZ' as CountryEnum,
        SE: 'SE' as CountryEnum,
        CH: 'CH' as CountryEnum,
        SY: 'SY' as CountryEnum,
        TW: 'TW' as CountryEnum,
        TJ: 'TJ' as CountryEnum,
        TZ: 'TZ' as CountryEnum,
        TH: 'TH' as CountryEnum,
        TL: 'TL' as CountryEnum,
        TG: 'TG' as CountryEnum,
        TK: 'TK' as CountryEnum,
        TO: 'TO' as CountryEnum,
        TT: 'TT' as CountryEnum,
        TN: 'TN' as CountryEnum,
        TR: 'TR' as CountryEnum,
        TM: 'TM' as CountryEnum,
        TC: 'TC' as CountryEnum,
        TV: 'TV' as CountryEnum,
        UG: 'UG' as CountryEnum,
        UA: 'UA' as CountryEnum,
        AE: 'AE' as CountryEnum,
        GB: 'GB' as CountryEnum,
        US: 'US' as CountryEnum,
        UM: 'UM' as CountryEnum,
        UY: 'UY' as CountryEnum,
        UZ: 'UZ' as CountryEnum,
        VU: 'VU' as CountryEnum,
        VE: 'VE' as CountryEnum,
        VN: 'VN' as CountryEnum,
        VG: 'VG' as CountryEnum,
        VI: 'VI' as CountryEnum,
        WF: 'WF' as CountryEnum,
        EH: 'EH' as CountryEnum,
        YE: 'YE' as CountryEnum,
        ZM: 'ZM' as CountryEnum,
        ZW: 'ZW' as CountryEnum
    };
    export type StatusEnum = 'PENDING' | 'APPROVED' | 'REJECTED' | 'CHANGE_REQUEST' | 'CHANGE_REJECTED' | 'EXPIRED' | 'CANCELED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        REJECTED: 'REJECTED' as StatusEnum,
        CHANGE_REQUEST: 'CHANGE_REQUEST' as StatusEnum,
        CHANGE_REJECTED: 'CHANGE_REJECTED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum
    };
}


