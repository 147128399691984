import { Injectable, computed, inject, signal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Verification, VerificationFull } from '@data/services/api';
import { filter, merge, takeUntil, tap } from 'rxjs';
import { ITriber } from './app-tribers.service';

const PATH_COLLECTION = 'verificaciones';

export interface IVerification
  extends Omit<VerificationFull, 'createdAt' | 'updatedAt' | 'triber'> {
  createdAt: firebase.default.firestore.Timestamp;
  updatedAt: firebase.default.firestore.Timestamp;
  triber: ITriber;
}

@Injectable({
  providedIn: 'root',
})
export class AppVerificationsService {
  private readonly db = inject(AngularFirestore);
  private readonly fire = inject(AngularFireAuth);

  items = signal<IVerification[]>(null!);

  count = computed(() => (this.items() ? this.items().length : 0));

  findOne = (uid: string) =>
    computed<IVerification | undefined>(() =>
      this.items().find((v) => v.uid === uid)
    );

  private userAuth$ = this.fire.authState;

  constructor() {
    this.userAuth$.subscribe((user) => {
      if (user) {
        this.listenChanges$().subscribe({
          complete: () => this.items.set(null!), // complete the observable because destroy because user logout, clean data
        });
      }
    });
  }

  private listVerification$() {
    const news$ = this.db
      .collection<IVerification>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Verification.StatusEnum.PENDING)
      )
      .stateChanges();
    const changes$ = this.db
      .collection<IVerification>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Verification.StatusEnum.CHANGE_REQUEST)
      )
      .stateChanges();
    return merge(news$, changes$);
  }

  listenChanges$() {
    return this.listVerification$().pipe(
      takeUntil(this.userAuth$.pipe(filter((user) => !user))), // destroy if user logout
      tap((items) => {
        items.forEach((action) => {
          const { doc, type } = action.payload;
          const data = doc.data();
          if (type === 'added') {
            return this.childAdded(data);
          }
          if (type === 'removed') {
            return this.childRemoved(data.uid);
          }
          if (type === 'modified') {
            return this.childChanged(data);
          }
        });

        if (!this.items()) {
          this.items.set([]); // empty if no items
        }
      })
    );
  }

  remove(id: string) {
    return this.db.collection(PATH_COLLECTION).doc(id).delete();
  }

  private childRemoved(uid: string) {
    this.items.update((items) =>
      items ? items.filter((item) => item.uid !== uid) : items
    );
  }

  private childAdded(data: IVerification) {
    this.items.update((items) => (items ? [...items, data] : [data]));
  }

  private childChanged(data: IVerification) {
    this.items.update((items) =>
      items ? items.map((item) => (item.uid === data.uid ? data : item)) : items
    );
  }
}
