import { Signal, WritableSignal } from '@angular/core';

export function childRemoved(uid: string, collection: WritableSignal<any[]>) {
  collection.update((items) =>
    items ? items.filter((item) => item.uid !== uid) : items
  );
}

// export function childAdded(data: T, collection : T[]) {
//   this.tribers.update((items) => (items ? [...items, data] : [data]));
// }

// export function childChanged(data: ITriberWithuser) {
//   this.tribers.update((items) =>
//     items ? items.map((item) => (item.uid === data.uid ? data : item)) : items
//   );
// }
