import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AppAuthService } from '@services/app-auth.service';
import { AppPublicationsApi } from '@services/app-publications.service';
import { AppResumesService } from '@services/app-resumes.service';
import { AppTriberService } from '@services/app-tribers.service';
import { AppVerificationsService } from '@services/app-verifications.service';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonComponent {
  private authApp = inject(AppAuthService);
  private afAuth = inject(AngularFireAuth);
  title: string | null = 'app';

  user$ = this.afAuth.currentUser;
  toggleUserMenu = false;

  countTribers = inject(AppTriberService).count;
  countResumes = inject(AppResumesService).count;
  countPublications = inject(AppPublicationsApi).count;
  countVerifications = inject(AppVerificationsService).count;

  showCount = {
    tribers: false,
    resumes: false,
    publications: false,
    insignias: false,
  };

  constructor() {
    effect(() => {
      this.showCount = {
        tribers: this.countTribers() > 0,
        resumes: this.countResumes() > 0,
        publications: this.countPublications() > 0,
        insignias: this.countVerifications() > 0,
      };
    });
  }

  async logout() {
    this.authApp.logout();
    window.location.replace('/');
  }

  changeRoute(pageComponent: any) {
    this.title = pageComponent.title;
  }
}
