/**
 * Tribuu API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Resume { 
    uid: string;
    publicIdentifier: string;
    professionalObjective: string;
    jobPositionPreference: string;
    expectedSalary: number;
    currency: Resume.CurrencyEnum;
    modality: Resume.ModalityEnum;
    locationChangeAvailability: boolean;
    jobType: Resume.JobTypeEnum;
    lastAcademicDegree: Resume.LastAcademicDegreeEnum | null;
    degree: string | null;
    schoolId: string;
    schoolCertificateFront: string | null;
    schoolCertificateBack: string | null;
    linkedinUrl: string | null;
    firstEmployment: boolean;
    video: string | null;
    scheduleId: string | null;
    triberId: string;
    status: Resume.StatusEnum;
    strikes: number | null;
    workScheme: Resume.WorkSchemeEnum;
    enabled: boolean | null;
    createdAt: Date;
    updatedAt: Date;
}
export namespace Resume {
    export type CurrencyEnum = 'MXN' | 'USD' | 'EUR' | 'ARS' | 'CLP' | 'COP' | 'DOP' | 'GTQ' | 'HNl' | 'HTG' | 'NIO' | 'PAB' | 'PEN' | 'PYG' | 'SOL' | 'SVC' | 'VES';
    export const CurrencyEnum = {
        MXN: 'MXN' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum,
        EUR: 'EUR' as CurrencyEnum,
        ARS: 'ARS' as CurrencyEnum,
        CLP: 'CLP' as CurrencyEnum,
        COP: 'COP' as CurrencyEnum,
        DOP: 'DOP' as CurrencyEnum,
        GTQ: 'GTQ' as CurrencyEnum,
        HNl: 'HNl' as CurrencyEnum,
        HTG: 'HTG' as CurrencyEnum,
        NIO: 'NIO' as CurrencyEnum,
        PAB: 'PAB' as CurrencyEnum,
        PEN: 'PEN' as CurrencyEnum,
        PYG: 'PYG' as CurrencyEnum,
        SOL: 'SOL' as CurrencyEnum,
        SVC: 'SVC' as CurrencyEnum,
        VES: 'VES' as CurrencyEnum
    };
    export type ModalityEnum = 'FACETOFACE' | 'REMOTE' | 'HYBRID' | 'ALL';
    export const ModalityEnum = {
        FACETOFACE: 'FACETOFACE' as ModalityEnum,
        REMOTE: 'REMOTE' as ModalityEnum,
        HYBRID: 'HYBRID' as ModalityEnum,
        ALL: 'ALL' as ModalityEnum
    };
    export type JobTypeEnum = 'FULLTIME' | 'PARTTIME' | 'PROYECT';
    export const JobTypeEnum = {
        FULLTIME: 'FULLTIME' as JobTypeEnum,
        PARTTIME: 'PARTTIME' as JobTypeEnum,
        PROYECT: 'PROYECT' as JobTypeEnum
    };
    export type LastAcademicDegreeEnum = 'EXPERIENCIA' | 'CURSOS' | 'SECUNDARIA' | 'BACHILLER' | 'TECNICO' | 'UNIVERSIDAD' | 'TRUNCA' | 'SINTITULO' | 'MAESTRIA' | 'DOCTORADO' | 'POSTDOCTORADO';
    export const LastAcademicDegreeEnum = {
        EXPERIENCIA: 'EXPERIENCIA' as LastAcademicDegreeEnum,
        CURSOS: 'CURSOS' as LastAcademicDegreeEnum,
        SECUNDARIA: 'SECUNDARIA' as LastAcademicDegreeEnum,
        BACHILLER: 'BACHILLER' as LastAcademicDegreeEnum,
        TECNICO: 'TECNICO' as LastAcademicDegreeEnum,
        UNIVERSIDAD: 'UNIVERSIDAD' as LastAcademicDegreeEnum,
        TRUNCA: 'TRUNCA' as LastAcademicDegreeEnum,
        SINTITULO: 'SINTITULO' as LastAcademicDegreeEnum,
        MAESTRIA: 'MAESTRIA' as LastAcademicDegreeEnum,
        DOCTORADO: 'DOCTORADO' as LastAcademicDegreeEnum,
        POSTDOCTORADO: 'POSTDOCTORADO' as LastAcademicDegreeEnum
    };
    export type StatusEnum = 'PENDING' | 'APPROVED' | 'REJECTED' | 'CHANGE_REQUEST' | 'CHANGE_REJECTED' | 'EXPIRED' | 'CANCELED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        REJECTED: 'REJECTED' as StatusEnum,
        CHANGE_REQUEST: 'CHANGE_REQUEST' as StatusEnum,
        CHANGE_REJECTED: 'CHANGE_REJECTED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum
    };
    export type WorkSchemeEnum = 'CONTRACT' | 'FREELANCE' | 'INTERSHIP' | 'PRACTICES' | 'SOCIAL';
    export const WorkSchemeEnum = {
        CONTRACT: 'CONTRACT' as WorkSchemeEnum,
        FREELANCE: 'FREELANCE' as WorkSchemeEnum,
        INTERSHIP: 'INTERSHIP' as WorkSchemeEnum,
        PRACTICES: 'PRACTICES' as WorkSchemeEnum,
        SOCIAL: 'SOCIAL' as WorkSchemeEnum
    };
}


