/**
 * Tribuu API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Triber } from './triber';


export interface Verification { 
    uid: string;
    triber?: Triber;
    triberId: string;
    status: Verification.StatusEnum;
    attempts: number;
    banned: boolean;
    addressVerification: Array<string>;
    photoIdentityPrimary: string | null;
    photoIdentitySecondary: string | null;
    recomendations: Array<string>;
    createdAt: Date;
    updatedAt: Date;
}
export namespace Verification {
    export type StatusEnum = 'PENDING' | 'APPROVED' | 'REJECTED' | 'CHANGE_REQUEST' | 'CHANGE_REJECTED' | 'EXPIRED' | 'CANCELED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        REJECTED: 'REJECTED' as StatusEnum,
        CHANGE_REQUEST: 'CHANGE_REQUEST' as StatusEnum,
        CHANGE_REJECTED: 'CHANGE_REJECTED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum
    };
}


