import { Injectable, computed, inject, signal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PublicationAllRelations, Resume } from '@data/services/api';
import { Subject, filter, merge, takeUntil, tap } from 'rxjs';
import { childRemoved } from 'src/app/common/helpers/collection.helper';
import { ITriber } from './app-tribers.service';

const PATH_COLLECTION = 'publicaciones';

export interface IPublication
  extends Omit<PublicationAllRelations, 'triber' | 'updatedAt'> {
  triber: ITriber;
  updatedAt: firebase.default.firestore.Timestamp;
  views: number;
  deleteAt?: firebase.default.firestore.Timestamp;
  aprovedAt?: firebase.default.firestore.Timestamp;
}

@Injectable({
  providedIn: 'root',
})
export class AppPublicationsApi {
  private readonly db = inject(AngularFirestore);
  private readonly fire = inject(AngularFireAuth);
  private destroy$ = new Subject<boolean>();

  //list publications
  publications = signal<IPublication[]>(null!);
  count = computed(() =>
    this.publications() ? this.publications().length : 0
  );

  sortedPublications = computed(() =>
    this.publications()
      ? [...this.publications()].sort(
          (a, b) => b.updatedAt.seconds - a.updatedAt.seconds
        )
      : null
  );

  // selector by id
  findOne = (uid: string) =>
    computed<IPublication | undefined>(() =>
      this.publications().find((resume) => resume.uid === uid)
    );

  private userAuth$ = this.fire.authState.pipe(
    tap((user) => {
      if (!user) this.destroy$.next(true);
      this.destroy$.complete();
    }),
    filter((user) => !!user)
  );

  constructor() {
    this.userAuth$.subscribe((user) => {
      if (user) {
        this.listenChangesOnTribers().subscribe({
          complete: () => this.publications.set(null!), // complete the observable because destroy because user logout, clean data
        });
      }
    });
  }

  private listResume() {
    const news$ = this.db
      .collection<IPublication>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Resume.StatusEnum.PENDING)
      )
      .stateChanges();
    const changes$ = this.db
      .collection<IPublication>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Resume.StatusEnum.CHANGE_REQUEST)
      )
      .stateChanges();

    return merge(news$, changes$);
  }

  listenChangesOnTribers() {
    return this.listResume().pipe(
      takeUntil(this.destroy$), // destroy if user logout
      // map((items) => {
      //   debugger;
      //   return items.filter(
      //     (item) =>
      //       item.payload.doc.data().status === Publication.StatusEnum.PENDING
      //   );
      // }),
      tap((items) => {
        items.forEach((action) => {
          const { doc, type } = action.payload;
          const data = doc.data();

          if (type === 'added') {
            this.publications.update((items) =>
              items ? [...items, data] : [data]
            );
            return;
          }
          if (type === 'removed') {
            childRemoved(data.uid, this.publications);
            return;
          }
          if (type === 'modified') {
            this.publications.update((items) =>
              items
                ? items.map((item) => (item.uid === data.uid ? data : item))
                : items
            );
          }
        });
        if (!this.publications()) {
          this.publications.set([]); // empty if no items
        }
      })
    );
  }
}
