import { inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AppAuthService } from './core/services/app-auth.service';
import { firstValueFrom, tap } from 'rxjs';

export function initializeApp(appAuth: AppAuthService) {
  return () => {
    return appAuth.fire.currentUser;
  };
}
