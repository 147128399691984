import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { SkeletonComponent } from './layout/skeleton/skeleton.component';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'auth',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard },
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    component: SkeletonComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'tribers',
        title: 'Tribers',
        loadChildren: () =>
          import('./modules/tribers/tribers.module').then(
            (m) => m.TribersModule
          ),
      },
      {
        path: 'publicaciones',
        loadChildren: () =>
          import('./modules/publications/publications.module').then(
            (m) => m.PublicationsModule
          ),
      },
      {
        path: 'bolsa-de-trabajo',
        loadChildren: () =>
          import('./modules/resumes/resumes.module').then(
            (m) => m.ResumesModule
          ),
      },
      {
        path: 'insignias',
        loadChildren: () =>
          import('./modules/badges/badges.module').then((m) => m.BadgesModule),
      },
      {
        path: 'usuarios',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
