/**
 * Tribuu API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AcademicGrade { 
    uid: string;
    lastAcademicDegree: AcademicGrade.LastAcademicDegreeEnum;
    files: Array<string>;
    verificationId: string;
    createdAt: Date;
    updatedAt: Date;
}
export namespace AcademicGrade {
    export type LastAcademicDegreeEnum = 'EXPERIENCIA' | 'CURSOS' | 'SECUNDARIA' | 'BACHILLER' | 'TECNICO' | 'UNIVERSIDAD' | 'TRUNCA' | 'SINTITULO' | 'MAESTRIA' | 'DOCTORADO' | 'POSTDOCTORADO';
    export const LastAcademicDegreeEnum = {
        EXPERIENCIA: 'EXPERIENCIA' as LastAcademicDegreeEnum,
        CURSOS: 'CURSOS' as LastAcademicDegreeEnum,
        SECUNDARIA: 'SECUNDARIA' as LastAcademicDegreeEnum,
        BACHILLER: 'BACHILLER' as LastAcademicDegreeEnum,
        TECNICO: 'TECNICO' as LastAcademicDegreeEnum,
        UNIVERSIDAD: 'UNIVERSIDAD' as LastAcademicDegreeEnum,
        TRUNCA: 'TRUNCA' as LastAcademicDegreeEnum,
        SINTITULO: 'SINTITULO' as LastAcademicDegreeEnum,
        MAESTRIA: 'MAESTRIA' as LastAcademicDegreeEnum,
        DOCTORADO: 'DOCTORADO' as LastAcademicDegreeEnum,
        POSTDOCTORADO: 'POSTDOCTORADO' as LastAcademicDegreeEnum
    };
}


