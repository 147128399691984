export const environment = {
  production: false,
  apiUrl: 'https://tribuu-core-3sillpkapa-uw.a.run.app',
  firebaseConfig: {
    apiKey: 'AIzaSyCLQQx5t6PpAuDlpQlgk9HmK-qPakvJvfc',
    authDomain: 'tribuu-stage.firebaseapp.com',
    databaseURL: 'https://tribuu-stage-default-rtdb.firebaseio.com',
    projectId: 'tribuu-stage',
    storageBucket: 'tribuu-stage.appspot.com',
    messagingSenderId: '337844499533',
    appId: '1:337844499533:web:bc09cd7943ec7af96a5804',
  },
};
