import { Injectable, computed, inject, signal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Resume, ResumeAllRelationsDto } from '@data/services/api';
import { Subject, filter, merge, takeUntil, tap } from 'rxjs';
import { childRemoved } from 'src/app/common/helpers/collection.helper';
import { ITriber } from './app-tribers.service';
const PATH_COLLECTION = 'bolsa-de-trabajo';

export interface IResume extends Omit<ResumeAllRelationsDto, 'triber'> {
  triber: ITriber;
}
@Injectable({
  providedIn: 'root',
})
export class AppResumesService {
  private readonly db = inject(AngularFirestore);
  private readonly fire = inject(AngularFireAuth);

  private destroy$ = new Subject<boolean>();

  //list resumes
  resumes = signal<IResume[]>(null!);
  count = computed(() => (this.resumes() ? this.resumes().length : 0));

  // selector by id
  findOne = (uid: string) =>
    computed<IResume | undefined>(() =>
      this.resumes().find((resume) => resume.uid === uid)
    );

  private userAuth$ = this.fire.authState.pipe(
    tap((user) => {
      if (!user) this.destroy$.next(true);
      this.destroy$.complete();
    }),
    filter((user) => !!user)
  );

  constructor() {
    this.userAuth$.subscribe((user) => {
      if (user) {
        this.listenChangesOnTribers().subscribe({
          complete: () => this.resumes.set(null!), // complete the observable because destroy because user logout, clean data
        });
      }
    });
  }

  private listResume() {
    const news$ = this.db
      .collection<IResume>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Resume.StatusEnum.PENDING)
      )
      .stateChanges();
    const changes$ = this.db
      .collection<IResume>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Resume.StatusEnum.CHANGE_REQUEST)
      )
      .stateChanges();

    return merge(news$, changes$);
  }

  listenChangesOnTribers() {
    return this.listResume().pipe(
      takeUntil(this.destroy$), // destroy if user logout
      tap((items) => {
        items.forEach((action) => {
          const { doc, type } = action.payload;
          const data = doc.data();

          if (type === 'added') {
            return this.childAdded(data);
          }
          if (type === 'removed') {
            return childRemoved(data.uid, this.resumes);
          }
          if (type === 'modified') {
            return this.childChanged(data);
          }
        });
        if (!this.resumes()) {
          this.resumes.set([]); // empty if no items
        }
      })
    );
  }

  private childAdded(data: IResume) {
    this.resumes.update((items) => (items ? [...items, data] : [data]));
  }

  private childChanged(data: IResume) {
    this.resumes.update((items) =>
      items ? items.map((item) => (item.uid === data.uid ? data : item)) : items
    );
  }
}
