import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Configuration, CoreApiModule } from './services/api';

@NgModule({
  declarations: [],
  imports: [CoreApiModule],
  providers: [
    {
      provide: Configuration,
      useFactory: () => {
        return new Configuration({
          basePath: environment.apiUrl,
          accessToken: () => sessionStorage.getItem('token') ?? '',
        });
      },
    },
  ],
})
export class DataModule {}
