import { Injectable, inject, signal } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ProfileApi, User } from '@data/services/api';

import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppAuthService {
  readonly fire = inject(AngularFireAuth);
  private profileService = inject(ProfileApi);

  profile = signal<User | null>(null);

  constructor() {
    // subscribe to changes token auth
    this.fire.idToken.subscribe((token) => {
      if (token) {
        sessionStorage.setItem('token', token);
        this.fetchProfile().subscribe();
      } else {
        sessionStorage.removeItem('token');
        this.profile.set(null);
      }
    });
  }

  fetchProfile() {
    return this.profileService.getUserInfo().pipe(
      tap((profile) => {
        this.profile.set(profile);
      })
    );
  }

  login(email: string, password: string) {
    return this.fire.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.fire.signOut();
  }
}
