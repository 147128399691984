<!-- <div class="absolute w-full h-full bg-black opacity-40 z-50"></div> -->
<!--
    Graphic from https://www.opendoodles.com/
-->

<div
  *ngIf="(breakpoint$ | async) !== 'desktopLarge'"
  class="grid h-screen px-4 bg-white place-content-center absolute w-screen z-50"
>
  <app-unsopported-mobile></app-unsopported-mobile>
</div>
<tui-root>
  <router-outlet></router-outlet>
</tui-root>
