import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  TUI_SANITIZER,
  TuiAlertModule,
  TuiButtonModule,
  TuiDialogModule,
  TuiRootModule,
} from '@taiga-ui/core';
import { TuiTagModule } from '@taiga-ui/kit';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './layout/components/sidebar/sidebar.component';
import { SkeletonComponent } from './layout/skeleton/skeleton.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { TUI_LANGUAGE, TUI_SPANISH_LANGUAGE } from '@taiga-ui/i18n';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { initializeApp } from './app.initializer';
import { AppAuthService } from './core/services/app-auth.service';
import { DataModule } from './data/data.module';
import { UnsupportedMobileComponent } from './layout/components/unsopported-mobile/unsupported-mobile.component';
registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    SkeletonComponent,
    SidebarComponent,
    UnsupportedMobileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TuiRootModule,
    AppRoutingModule,
    DataModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiTagModule,
    TuiLetModule,
    TuiButtonModule,
  ],
  providers: [
    AppAuthService,
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_SPANISH_LANGUAGE),
    },
    { provide: NZ_I18N, useValue: es_ES },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppAuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
