import { Component, inject } from '@angular/core';
import { TuiBreakpointService } from '@taiga-ui/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'cms-tribu';
  breakpoint$ = inject(TuiBreakpointService);
  constructor(private primengConfig: PrimeNGConfig) {
    this.primengConfig.ripple = true;
  }
}
