import { Injectable, computed, inject, signal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Triber, TriberProfile, User } from '@data/services/api';
import { filter, merge, takeUntil, tap } from 'rxjs';

const PATH_COLLECTION = 'tribers';

export interface ITriber
  extends Omit<
    TriberProfile & { user: User },
    'updatedAt' | 'documentsIdentity'
  > {
  updatedAt: firebase.default.firestore.Timestamp;
  documentsIdentity: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AppTriberService {
  private readonly db = inject(AngularFirestore);
  private readonly fire = inject(AngularFireAuth);

  tribers = signal<ITriber[]>(null!);

  count = computed(() => (this.tribers() ? this.tribers().length : 0));

  findOne = (uid: string) =>
    computed<ITriber | undefined>(() => {
      const find = this.tribers().find((triber) => triber.uid === uid);
      return find;
    });

  private userAuth$ = this.fire.authState;

  constructor() {
    this.userAuth$.subscribe((user) => {
      if (user) {
        this.listenChangesOnTribers().subscribe({
          complete: () => {
            this.tribers.set(null!);
            console.warn('complete subscription for tribers');
          }, // complete the observable because destroy because user logout, clean data
        });
      }
    });
  }

  // completeReviewTriber(changes: CreateChangeTriberDto[], triberId:string) {
  //   return this.tribersService.sendReviewTriber(changes).pipe(tap(() => {
  //   }));
  // }

  private listTribers() {
    const news$ = this.db
      .collection<ITriber>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Triber.StatusEnum.PENDING)
      )
      .stateChanges();
    const changes$ = this.db
      .collection<ITriber>(PATH_COLLECTION, (ref) =>
        ref.where('status', '==', Triber.StatusEnum.CHANGE_REQUEST)
      )
      .stateChanges();
    return merge(news$, changes$);
  }

  listenChangesOnTribers() {
    console.warn('init tribers subscription');
    return this.listTribers().pipe(
      takeUntil(this.userAuth$.pipe(filter((user) => !user))), // destroy if user logout
      tap((items) => {
        items.forEach((action) => {
          const { doc, type } = action.payload;
          const data = doc.data();
          console.log('data', data);

          if (type === 'added') {
            return this.childAdded(data);
          }
          if (type === 'removed') {
            return this.childRemoved(data.uid);
          }
          if (type === 'modified') {
            return this.childChanged(data);
          }
        });

        if (!this.tribers()) {
          this.tribers.set([]); // empty if no items
        }
      })
    );
  }

  remove(id: string) {
    return this.db.collection(PATH_COLLECTION).doc(id).delete();
  }

  private childRemoved(uid: string) {
    this.tribers.update((items) =>
      items ? items.filter((item) => item.uid !== uid) : items
    );
  }

  private childAdded(data: ITriber) {
    this.tribers.update((items) => (items ? [...items, data] : [data]));
  }

  private childChanged(data: ITriber) {
    this.tribers.update((items) =>
      items ? items.map((item) => (item.uid === data.uid ? data : item)) : items
    );
  }
}
