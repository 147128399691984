/**
 * Tribuu API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Publication { 
    uid: string;
    folio: string | null;
    title: string;
    rfc: string | null;
    slug: string;
    video: string | null;
    stars: number;
    description: string;
    linkedin: string | null;
    price: number | null;
    currency: Publication.CurrencyEnum;
    degree: string | null;
    modality: Publication.ModalityEnum;
    limitGroup: number;
    timeUnit: Publication.TimeUnitEnum;
    studyLevel: Publication.StudyLevelEnum;
    academicCertificateFront: string | null;
    academicCertificateBack: string | null;
    /**
     * duracion de la sesión en minutos
     */
    duration: number;
    /**
     * periodo de le entrega 
     */
    deliveryPeriod: number;
    enabled: boolean;
    similarJob: boolean;
    triberId: string;
    status: Publication.StatusEnum;
    views: number;
    createdAt: Date;
    updatedAt: Date;
    categoryId: string;
    schoolId: string;
    paymentModelId: string | null;
    images: Array<string>;
    errorsPending: object | null;
    strikes: number | null;
    scheduleId: string;
}
export namespace Publication {
    export type CurrencyEnum = 'MXN' | 'USD' | 'EUR' | 'ARS' | 'CLP' | 'COP' | 'DOP' | 'GTQ' | 'HNl' | 'HTG' | 'NIO' | 'PAB' | 'PEN' | 'PYG' | 'SOL' | 'SVC' | 'VES';
    export const CurrencyEnum = {
        MXN: 'MXN' as CurrencyEnum,
        USD: 'USD' as CurrencyEnum,
        EUR: 'EUR' as CurrencyEnum,
        ARS: 'ARS' as CurrencyEnum,
        CLP: 'CLP' as CurrencyEnum,
        COP: 'COP' as CurrencyEnum,
        DOP: 'DOP' as CurrencyEnum,
        GTQ: 'GTQ' as CurrencyEnum,
        HNl: 'HNl' as CurrencyEnum,
        HTG: 'HTG' as CurrencyEnum,
        NIO: 'NIO' as CurrencyEnum,
        PAB: 'PAB' as CurrencyEnum,
        PEN: 'PEN' as CurrencyEnum,
        PYG: 'PYG' as CurrencyEnum,
        SOL: 'SOL' as CurrencyEnum,
        SVC: 'SVC' as CurrencyEnum,
        VES: 'VES' as CurrencyEnum
    };
    export type ModalityEnum = 'FACETOFACE' | 'REMOTE' | 'HYBRID' | 'ALL';
    export const ModalityEnum = {
        FACETOFACE: 'FACETOFACE' as ModalityEnum,
        REMOTE: 'REMOTE' as ModalityEnum,
        HYBRID: 'HYBRID' as ModalityEnum,
        ALL: 'ALL' as ModalityEnum
    };
    export type TimeUnitEnum = 'MINUTES' | 'HOURS' | 'DAYS' | 'WEEKS' | 'MONTHS';
    export const TimeUnitEnum = {
        MINUTES: 'MINUTES' as TimeUnitEnum,
        HOURS: 'HOURS' as TimeUnitEnum,
        DAYS: 'DAYS' as TimeUnitEnum,
        WEEKS: 'WEEKS' as TimeUnitEnum,
        MONTHS: 'MONTHS' as TimeUnitEnum
    };
    export type StudyLevelEnum = 'EXPERIENCIA' | 'CURSOS' | 'SECUNDARIA' | 'BACHILLER' | 'TECNICO' | 'UNIVERSIDAD' | 'TRUNCA' | 'SINTITULO' | 'MAESTRIA' | 'DOCTORADO' | 'POSTDOCTORADO';
    export const StudyLevelEnum = {
        EXPERIENCIA: 'EXPERIENCIA' as StudyLevelEnum,
        CURSOS: 'CURSOS' as StudyLevelEnum,
        SECUNDARIA: 'SECUNDARIA' as StudyLevelEnum,
        BACHILLER: 'BACHILLER' as StudyLevelEnum,
        TECNICO: 'TECNICO' as StudyLevelEnum,
        UNIVERSIDAD: 'UNIVERSIDAD' as StudyLevelEnum,
        TRUNCA: 'TRUNCA' as StudyLevelEnum,
        SINTITULO: 'SINTITULO' as StudyLevelEnum,
        MAESTRIA: 'MAESTRIA' as StudyLevelEnum,
        DOCTORADO: 'DOCTORADO' as StudyLevelEnum,
        POSTDOCTORADO: 'POSTDOCTORADO' as StudyLevelEnum
    };
    export type StatusEnum = 'PENDING' | 'APPROVED' | 'REJECTED' | 'CHANGE_REQUEST' | 'CHANGE_REJECTED' | 'EXPIRED' | 'CANCELED';
    export const StatusEnum = {
        PENDING: 'PENDING' as StatusEnum,
        APPROVED: 'APPROVED' as StatusEnum,
        REJECTED: 'REJECTED' as StatusEnum,
        CHANGE_REQUEST: 'CHANGE_REQUEST' as StatusEnum,
        CHANGE_REJECTED: 'CHANGE_REJECTED' as StatusEnum,
        EXPIRED: 'EXPIRED' as StatusEnum,
        CANCELED: 'CANCELED' as StatusEnum
    };
}


